import React, { Component } from "react";
import { withRouter } from 'react-router-dom';
import { Link } from "react-router-dom";
import { sanitize } from 'dompurify';
import moment from "moment";
import ConfirmMessagePopUpModal from "./../PopUp/ConfirmMessagePopUpModal"
import { ContextMenu, MenuItem, ContextMenuTrigger, showMenu, hideMenu } from "react-contextmenu";
import CollectionpopUp from "./../PopUp/CollectionpopUp"
class GenAILibraryThreadItem extends Component {
    constructor(props) {
        super(props);
        this.state = {
            customWidth: props.customWidth,
            customHeight: props.customHeight,
            source: props.source,
            threadCollections: props.threadCollections,
            confirmMessageContent: '',
            confirmMessageShowModal: false,
            confirmMessageId: -1,
            collectionPopup: false,
        }
    }

    renderDisplayString = () => {
        let { display_string, output_answer } = this.state.source.answeringinfo;
        let { source } = this.state;
        if (display_string.length > 0) {

            let finalDisplayString = display_string.map((dsitem, dsindex) => {
                let display_ans = dsitem.display_ans.replace(/<br\/>/g, '').replace(/<br>/g, '')
                let temp = [];
                const div = document.createElement('div');
                let _sectionData = display_ans;
                if (_sectionData) {
                    _sectionData = _sectionData.replace(/<br>·/g, '<br>');
                }
                div.innerHTML = _sectionData;
                const spans = div.getElementsByTagName('span');
                if (spans && spans.length > 0) {
                    for (let span = 0; span < spans.length; span++) {
                        temp.push(spans[span].innerHTML);
                    }

                    display_ans = temp.join('').replace(/<(?:.|\n)*?>/gm, ' ').replace(/<(?:.|\n)*?>/gm, ' ').replace(/\s{2,}/g, ' ').trim()
                } else {
                    display_ans = display_ans.replace(/<(?:.|\n)*?>/gm, ' ').replace(/<(?:.|\n)*?>/gm, ' ').replace(/\s{2,}/g, ' ').trim()
                }
                return display_ans;
            }).join(' ')
            return (<Link to={`/genAISearch/${source.answeringinfo.session_id}`} title={finalDisplayString} dangerouslySetInnerHTML={{ __html: sanitize(`${finalDisplayString}`) }} className="gen-ai-answering-library-thread-item-ans">
            </Link>)
        } else {
            return (
                <Link to={`/genAISearch/${source.answeringinfo.session_id}`} title={output_answer} dangerouslySetInnerHTML={{ __html: sanitize(`${output_answer}`) }} className="gen-ai-answering-library-thread-item-ans">
                </Link>)
        }
    }
    showConfirmMessage = (message, id) => {
        this.setState({
            confirmMessageContent: message,
            confirmMessageShowModal: true,
            confirmMessageId: id
        })
    }

    confirmHandler = async () => {
        const { confirmMessageId } = this.state;
        this.setState(
            {

                confirmMessageContent: "",
                confirmMessageShowModal: false,
                // confirmMessageId: -1
            },
            async () => {
                await this.props.deleteAnswerThreadDataBySessionId(confirmMessageId);
                this.setState({ confirmMessageId: -1 });
            }
        );
    }


    closeConfirmMessage = () => {
        this.setState({
            confirmMessageContent: "",
            confirmMessageShowModal: false,
            confirmMessageIndex: -1
        })
    }
    handleDeleteThreadClick = (e, data) => {
        this.showConfirmMessage("Are you sure you want to delete this thread?", data._id)
    }
    handleAddtoCollectionClick = (e, data) => {
        this.setState({ collectionPopup: true });
    }
    handleLeftMouseClick = (e, _id) => {
        const x = e.clientX;
        const y = e.clientY;
        this.props.handleOnOpenedContextMenuClick(_id);
        showMenu({
            position: { x, y },
            id: _id
        });
    }
    onContextMenuHide = () => {
        this.props.handleOnOpenedContextMenuClick("");
    }
    componentWillReceiveProps(nextProps) {
        let { source } = this.state;
        if (nextProps && nextProps.openedContextMenuId !== source.answeringinfo.session_id
        ) {
            hideMenu({
                id: source.answeringinfo.session_id
            });
        }
        if (nextProps && nextProps.openedContextMenuId !== `CL-${source.answeringinfo.session_id}`) {
            hideMenu({
                id: `CL-${source.answeringinfo.session_id}`
            });
        }
    }
    handleCloseCollection = async (isUpdateAnything) => {
        this.setState({ collectionPopup: false });
        if (isUpdateAnything) {
            await this.props.updateThreadAndCollection();
        }
    };
    render() {
        let { source, threadCollections } = this.state;
        let { updatedatehour } = this.state.source.eventinfo;
        const { confirmMessageContent, confirmMessageShowModal } = this.state;
        return (<>
            <div className="gen-ai-answering-library-thread-item">
                <div className="gen-ai-answering-library-thread-item-title">
                    <Link to={`/genAISearch/${source.answeringinfo.session_id}`} title={source.answeringinfo.question} className="gen-ai-answering-library-thread-item-title-link" >
                        {source.answeringinfo.question}
                    </Link>
                </div>
                {this.renderDisplayString()}

                <div className="gen-ai-answering-library-thread-item-time-action">
                    <div className="gen-ai-answering-library-thread-item-time">
                        <div className="gen-ai-answering-library-thread-item-time-ago" title={moment(updatedatehour).format("MMM DD, YYYY hh:mm a")} data-state="closed">
                            <span>
                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="clock" className="svg-inline--fa fa-clock fa-sm " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                    <path fill="currentColor" d="M464 256A208 208 0 1 1 48 256a208 208 0 1 1 416 0zM0 256a256 256 0 1 0 512 0A256 256 0 1 0 0 256zM232 120V256c0 8 4 15.5 10.7 20l96 64c11 7.4 25.9 4.4 33.3-6.7s4.4-25.9-6.7-33.3L280 243.2V120c0-13.3-10.7-24-24-24s-24 10.7-24 24z"></path>
                                </svg>
                            </span>
                            <span className="truncate">&nbsp; {moment(updatedatehour).fromNow()}</span>
                        </div>
                        {(source.answeringinfo.attachedFiles && source.answeringinfo.attachedFiles.length > 0) &&
                            <>&nbsp;&nbsp;
                                <div className="gen-ai-answering-library-thread-item-time-ago" title="Multi file thread" data-state="closed">
                                    <span>
                                        <svg fill="currentColor" className="svg-inline--fa fa-clock fa-sm " version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52.623 52.623" >
                                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                            <g id="SVGRepo_iconCarrier">
                                                <path d="M14.371,52.623c-3.535,0-6.849-1.368-9.332-3.852s-3.852-5.797-3.852-9.332c0-3.534,1.368-6.848,3.852-9.331L32.333,2.814 c3.905-3.903,9.973-3.728,14.114,0.413c4.141,4.141,4.319,10.208,0.414,14.114L21.22,42.982c-2.407,2.407-6.327,2.411-8.738,0 c-2.409-2.41-2.408-6.33,0-8.738l17.369-17.369c0.586-0.586,1.535-0.586,2.121,0c0.586,0.586,0.586,1.535,0,2.121L14.603,36.365 c-1.239,1.239-1.239,3.256,0,4.496c1.241,1.239,3.257,1.237,4.496,0L44.74,15.22c2.695-2.696,2.518-6.94-0.414-9.872 s-7.176-3.109-9.872-0.413L7.16,32.229c-1.917,1.917-2.973,4.478-2.973,7.21c0,2.733,1.056,5.294,2.973,7.211 s4.478,2.973,7.211,2.973c2.732,0,5.293-1.056,7.21-2.973l27.294-27.294c0.586-0.586,1.535-0.586,2.121,0s0.586,1.535,0,2.121 L23.702,48.771C21.219,51.254,17.905,52.623,14.371,52.623z"></path>
                                            </g>
                                        </svg>
                                    </span>
                                    <span className="truncate">&nbsp; Multi file thread</span>
                                </div>
                            </>
                        }
                    </div>
                    <div className="gen-ai-answering-library-thread-item-action">
                        {(!window.location.pathname.startsWith('/genAICollection/')) &&
                            <>
                                {threadCollections.length > 0 &&
                                    <div>
                                        <span>
                                            <ContextMenuTrigger id={`CL-${source.answeringinfo.session_id}`} >
                                                <button title="Collections" data-testid="thread-dropdown-menu" onClick={e => this.handleLeftMouseClick(e, `CL-${source.answeringinfo.session_id}`)} type="button" className="gen-ai-answering-library-thread-item-action-menu">
                                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="grid-round-2" className="svg-inline--fa fa-grid-round-2 fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path fill="currentColor" d="M128 176a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm96-48A96 96 0 1 1 32 128a96 96 0 1 1 192 0zM128 432a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm96-48A96 96 0 1 1 32 384a96 96 0 1 1 192 0zM336 128a48 48 0 1 0 96 0 48 48 0 1 0 -96 0zm48 96a96 96 0 1 1 0-192 96 96 0 1 1 0 192zm0 208a48 48 0 1 0 0-96 48 48 0 1 0 0 96zm96-48a96 96 0 1 1 -192 0 96 96 0 1 1 192 0z">
                                                        </path>
                                                    </svg>
                                                </button>
                                            </ContextMenuTrigger>
                                            <ContextMenu onHide={e => this.onContextMenuHide()} id={`CL-${source.answeringinfo.session_id}`} className="gen-ai-answering-library-thread-collections-item-contextMenu">
                                                <div className="gen-ai-answering-library-thread-item-contextMenu-collections-title">
                                                    Collections
                                                </div>
                                                <div className="gen-ai-answering-library-thread-item-contextMenu-collections-item">
                                                    {threadCollections.map((tcItem, tcIndex) => {
                                                        return (<div className="gen-ai-answering-library-thread-item-contextMenu-collections-item-title">
                                                            <Link to={`/genAICollection/${tcItem.collection_id}`} title={`${tcIndex + 1}. ${tcItem.collection_name}`} className="gen-ai-answering-library-thread-collection-item-title-link" >
                                                                {tcIndex + 1}. {tcItem.collection_name}
                                                            </Link>
                                                        </div>)
                                                    })}
                                                </div>
                                            </ContextMenu>
                                        </span>
                                    </div>
                                }
                                <button data-testid="add-to-collection" title={threadCollections.length > 0 ? "Update Collection" : "Add to Collection"} onClick={this.handleAddtoCollectionClick} type="button" className="gen-ai-answering-library-thread-item-action-add" data-state="closed">
                                    <div className="flex items-center leading-none justify-center gap-xs">
                                        {threadCollections.length > 0 ? <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="pen-to-square" className="svg-inline--fa fa-pen-to-square fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                            <path fill="currentColor" d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"></path>
                                        </svg> :
                                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" className="svg-inline--fa fa-plus fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                <path fill="currentColor" d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z"></path>
                                            </svg>
                                        }
                                    </div>
                                </button>
                                <div>
                                    <span>
                                        <ContextMenuTrigger id={source.answeringinfo.session_id} >
                                            <button data-testid="thread-dropdown-menu" onClick={e => this.handleLeftMouseClick(e, source.answeringinfo.session_id)} type="button" className="gen-ai-answering-library-thread-item-action-menu">

                                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="ellipsis" className="svg-inline--fa fa-ellipsis fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                                                    <path fill="currentColor" d="M432 256a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zm-160 0a48 48 0 1 1 -96 0 48 48 0 1 1 96 0zM64 304a48 48 0 1 1 0-96 48 48 0 1 1 0 96z"></path>
                                                </svg>
                                            </button>
                                        </ContextMenuTrigger>
                                        <ContextMenu id={source.answeringinfo.session_id} onHide={e => this.onContextMenuHide()} className="gen-ai-answering-library-thread-item-contextMenu">
                                            <MenuItem data={{ _id: source.answeringinfo.session_id }} onClick={this.handleAddtoCollectionClick}>
                                                {threadCollections.length > 0 ? <>
                                                    <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="pen-to-square" className="svg-inline--fa fa-pen-to-square fa-fw fa-1x " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                                                        <path fill="currentColor" d="M441 58.9L453.1 71c9.4 9.4 9.4 24.6 0 33.9L424 134.1 377.9 88 407 58.9c9.4-9.4 24.6-9.4 33.9 0zM209.8 256.2L344 121.9 390.1 168 255.8 302.2c-2.9 2.9-6.5 5-10.4 6.1l-58.5 16.7 16.7-58.5c1.1-3.9 3.2-7.5 6.1-10.4zM373.1 25L175.8 222.2c-8.7 8.7-15 19.4-18.3 31.1l-28.6 100c-2.4 8.4-.1 17.4 6.1 23.6s15.2 8.5 23.6 6.1l100-28.6c11.8-3.4 22.5-9.7 31.1-18.3L487 138.9c28.1-28.1 28.1-73.7 0-101.8L474.9 25C446.8-3.1 401.2-3.1 373.1 25zM88 64C39.4 64 0 103.4 0 152V424c0 48.6 39.4 88 88 88H360c48.6 0 88-39.4 88-88V312c0-13.3-10.7-24-24-24s-24 10.7-24 24V424c0 22.1-17.9 40-40 40H88c-22.1 0-40-17.9-40-40V152c0-22.1 17.9-40 40-40H200c13.3 0 24-10.7 24-24s-10.7-24-24-24H88z"></path>
                                                    </svg>
                                                    <span>Update Collection</span></>

                                                    : <>
                                                        <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="plus" className="svg-inline--fa fa-plus fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M248 72c0-13.3-10.7-24-24-24s-24 10.7-24 24V232H40c-13.3 0-24 10.7-24 24s10.7 24 24 24H200V440c0 13.3 10.7 24 24 24s24-10.7 24-24V280H408c13.3 0 24-10.7 24-24s-10.7-24-24-24H248V72z"></path></svg>
                                                        <span>Add to Collection</span>
                                                    </>
                                                }
                                            </MenuItem>
                                            <MenuItem data={{ _id: source.answeringinfo.session_id }} onClick={this.handleDeleteThreadClick}>
                                                <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="trash" className="svg-inline--fa fa-trash fa-fw " role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path fill="currentColor" d="M177.1 48h93.7c2.7 0 5.2 1.3 6.7 3.6l19 28.4h-145l19-28.4c1.5-2.2 4-3.6 6.7-3.6zM354.2 80L317.5 24.9C307.1 9.4 289.6 0 270.9 0H177.1c-18.7 0-36.2 9.4-46.6 24.9L93.8 80H80.1 32 24C10.7 80 0 90.7 0 104s10.7 24 24 24H35.6L59.6 452.7c2.5 33.4 30.3 59.3 63.8 59.3H324.6c33.5 0 61.3-25.9 63.8-59.3L412.4 128H424c13.3 0 24-10.7 24-24s-10.7-24-24-24h-8H367.9 354.2zm10.1 48L340.5 449.2c-.6 8.4-7.6 14.8-16 14.8H123.4c-8.4 0-15.3-6.5-16-14.8L83.7 128H364.3z"></path></svg>
                                                <span>Delete Thread</span>
                                            </MenuItem>
                                        </ContextMenu>
                                    </span>
                                </div>
                            </>}
                    </div>
                </div>
            </div>
            {confirmMessageShowModal && <ConfirmMessagePopUpModal content={confirmMessageContent} showModal={confirmMessageShowModal} handleClose={() => this.closeConfirmMessage()} handleConfirm={() => this.confirmHandler()} />}
            {this.state.collectionPopup && (
                <CollectionpopUp
                    showModal={this.state.collectionPopup}
                    handleClose={this.handleCloseCollection}
                    customWidth={this.state.customWidth}
                    sessionId={source.answeringinfo.session_id}
                    threadCollections={threadCollections.map(x => x.collection_id)}
                />
            )}
        </>)
    }
}
export default withRouter(GenAILibraryThreadItem);

