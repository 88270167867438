import './RightPanel.css';
import { TuningIcon, ArrowRight, ImportantIcon, arrowIconBlue, ArrowRightReverse ,
    FemaleIcon,MaleIcon,actionDropDownReverse,ActionsArrowIcon,Eraser,EditorPen,Tick
} from "../../assets";
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import DeletePopUp from './DeletePopUp';
import AlertMessagePopUpModal from '../PopUp/AlertMessagePopUpModal';


import { useEffect, useState } from 'react';
import PopUpModal from '../PopUp/PopUpModal';
import AliasModal from './AliasModal';
import ApiServices from "../../utils/apiServices";
import AddLexiModal from './AddLexiModal';
import SuccesModal from './SuccesModal';

const RightPanel = (props) => {
    const [voiceSliderClick, setVoiceSliderClick] = useState(false);
    const [rateSliderClick, setRateSliderClick] = useState(false);
    const [rateRelative, setRateRelative] = useState(100);
    const [rateConstant,setRateConstant] = useState("Default")
    const [langSelected, setLangSelected] = useState('English (United States)');
    const [voiceSelected,SetVoiceSelected] = useState('');
    const [isVoice, setIsVoice] = useState(false);
    const [insvalue, setInsValue] = useState();

    const [textselected, setTextSelected] = useState('')

    const [proSliderClick,setProSliderClick] = useState(false);
    const [lexiconSliderClick, setLexiconSliderClicl] = useState(false);
    const [alias , setAlias] = useState(true);
    const [phoneme , setPhoneme] = useState(false);
    const [modal,setModal] = useState(false);

    const [tuning , setTunning] = useState(true);
    const [Comment , setComment] = useState(false);
    const [relative ,setRelative] = useState(true);
    const [constant ,setConstant] = useState(false);
    const [allLexiconFile, setAllLexiconFile ] = useState([]);
    const [allLexiconFileName, setAllLexiconFileName] = useState([]);
    const [selectedLexiconName , setSelectedLexiName] = useState();
    const [selectedLexi, setSelectedLexi] = useState();
    const [addLexiModal , setAddLexiModal] = useState();

    const [phonemeValue , setPhonemeValue] = useState();
    const [isPhoneme , setIsPhoneme] = useState();

    const [aliasValue , setAliasValue] = useState();
    const [isAlias , setIsAlias] = useState(false);

    const [inputValue , setInputValue] = useState(1);
    const [inputError, setInputError] = useState(false);

    const [success, setSuccess] = useState(false)

    const [prevValue , setPreValue] = useState(1);
    const getAllVoice = new Map();
    

    getAllVoice.set('English (United States)',['Jenny','AvaMultilingual','AndrewMultilingual','EmmaMultilingual','BrianMultilingual']);

    useEffect(()=>{
        let val1 = ((rateRelative - 100))
        setInputValue(rateRelative/100)
        props.setRate(val1)
        props.setIsRateSelected(true)
    },[rateRelative]);

    useEffect (()=>{
        setTextSelected(props.textselected)
    },[props.textselected])

    useEffect(()=>{
        props.setIsPhoneme(true);
        props.setPhonemeValue(phonemeValue)
    },[isPhoneme])
    useEffect(()=>{
        props.setRate(rateConstant)
        props.setIsRateSelected(true)
    },[rateConstant])

    const InputRateSubmit = ()=>{
        let input1 = inputValue * 100
        if(input1 <= 300 && input1 >= 10 ){
            setRateRelative(input1)
            setInputError(false)
            setSuccess(true);
        }
        else{
            setInputError(true)
            setSuccess(false)
        }
    }
    useEffect (()=>{
        FetchLexiconData()
    },[])

    useEffect(()=>{
        setVoiceSliderClick(false);
        setRateSliderClick(false);
        setProSliderClick(false);
        setLexiconSliderClicl(false);
        SetVoiceSelected('');
        setRelative(true);
        setConstant(false);
        setModal(false);
        setRateRelative(100);
    },[props.id])

    const handleChange = (event) => {
        setLangSelected(event.target.value);
        props.setlang(event.target.value);
    };

    const handleChangeRate = (event) =>{
        if(event.target.value == 'Default'){
            setRateConstant(0)
        }
        else{
        setRateConstant(event.target.value)
        }
    }
    const increseRate = ()=>{
        let incre
        if(rateRelative == 300 ){
           setRateRelative(rateRelative)
        }
        else{
            incre = rateRelative + 10
            setRateRelative(incre)
        }
        
    }
    const decreaseRate = () =>{
        let decre ;
        if(rateRelative == 10){
            setRateRelative(rateRelative)
         }
         else{
            decre = rateRelative - 10;
            setRateRelative(decre)
        }
    }   
     const handleVoiceSelect = (e) =>{
            
        SetVoiceSelected(e.target.getAttribute('data-value'));
        props.setVoice(e.target.getAttribute('data-value'));
        props.setVoiceSelected(true);
        props.setIsVoiceChanged(true)
        setIsVoice(true)

        /*else{
            SetVoiceSelected('Jenny');
            props.setVoice('Jenny');
            props.setVoiceSelected(false);
            setIsVoice(false)
            props.setIsVoiceChanged(true)
        }*/
    }
        const displayOptions = (lang)=>{
            let value1 = getAllVoice.get(lang);
            return(
                value1?.map((val,i)=>{
                    return(
                        <button className={(voiceSelected !== val)?`${val}`:`${val} selected123`} 
                        onClick = {(e)=>{handleVoiceSelect(e)}} 
                        data-value={val}
                        style={{
                            backgroundColor:"rgb(239, 246, 252)",
                            margin: "1.5px",
                            border: "1px solid #c7e0f4",
                            borderRadius: "0.5rem",
                            color:"rgb(50, 49, 48)",
                            minWidth:"100px"
                        }}>
                            <div style= {{fontSize: "14px",fontWeight: "400",whiteSpace: "nowrap",lineHeight:"100%",display:"block",margin:"4px 0px"}} data-value={val} className={`${val}`}>{(i % 2 === 0)?<img data-value={val} src={FemaleIcon}></img>:<img data-value={val} src ={MaleIcon}></img>}</div>
                            <div style={{fontSize: "14px",fontWeight: "400",whiteSpace: "nowrap",lineHeight:"100%",display:"block",margin:"4px 0px"}} data-value={val} className={`${val}`}>{val}</div>
                        </button>
                    )
                })
            )
        }
        const FetchLexiconData = async() =>{
            let service = new ApiServices();
            let res = await service.getLexiconFile();
            setAllLexiconFile(res);
            let allname = [];
              res.forEach(ele => {
                allname = allname.concat([ele.name])
            });
            setAllLexiconFileName(allname)
        } 
        const handleChangeLexicon = (event) =>{
            let filter =allLexiconFile.filter((val)=>{
                return val.name === event.target.value
            }) 
            setSelectedLexi(filter[0])
            setSelectedLexiName(filter[0].name)
            
            props.setLexiconID(filter[0].id)
            props.setIsLexicon(true);
        }
        
        const dispalyLexiconData = () =>{
            return(
                allLexiconFileName.map((val)=>{
                    
                    return (
                        <MenuItem sx={{ fontSize: "14px",fontWeight: "400",lineHeight:"100%",color:"rgb(50, 49, 48)" }} value={val}>{val}</MenuItem>  
                    )
                })
            )
        }
        const handleAddAlias = (e) =>{
            setAliasValue(e.target.value);
            setIsAlias(true);
        } 
        const handleAliasSubmit = () => {
            props.setIsAlias(true);
            props.setAliasValue(aliasValue);
        }
        
        
    return (
        <>
            <div role="tablist" className="ms-FocusZone css-191 ms-Pivot root-507">
                <button className={(!tuning)?'ms-Button ms-Button--action ms-Button--command ms-Pivot-link is-selected linkIsSelected-517' : 'underLinetunning'}
                onClick={()=>{setTunning(true)
                    setComment(false)
                }}>
                    <span className='ms-Button-flexContainer flexContainer-320' style={{ fontSize: "0.75rem", fontWeight: "600" }}>
                        <span className='ms-Pivot-linkContent linkContent-513'>
                            <span className="ms-Pivot-icon icon-516">
                                <img src={TuningIcon} />
                                <span className='ms-Pivot-text text-514'>Tuning</span>
                            </span>
                        </span>
                    </span>
                </button>
                <button className={(!Comment)?'ms-Button ms-Button--action ms-Button--command ms-Pivot-link is-selected linkIsSelected-517':"underlineComment"}
                onClick ={()=>{setComment(true)
                    setTunning(false)
                }}>
                    <span className='ms-Button-flexContainer flexContainer-320' style={{ fontSize: "0.75rem", fontWeight: "600" }}>
                        <span className='ms-Pivot-linkContent linkContent-513'>
                            <span className="ms-Pivot-icon icon-516">
                                <img src={TuningIcon} />
                                <span className='ms-Pivot-text text-514'>Comments</span>
                            </span>
                        </span>
                    </span>
                </button>
            </div>
            {(tuning) && <>
            <div className='panel-frame-jn5c3tzmdb acc-panel-frame'>
                <div className='panel-body'>
                    <div className='tuning-control-wrapper-rq1cjbhtkv '>
                        <div className='header1'>
                            <span className="collapse-btn-wrapper1">
                                <button className="ms-Button ms-Button--icon root-309"
                                    onClick={() => setVoiceSliderClick(!voiceSliderClick)}>
                                    <span className='ms-Button-flexContainer flexContainer-189'>
                                        {(!voiceSliderClick) ? <img src={ArrowRight} /> : <img src={ArrowRightReverse} />}
                                    </span></button>
                            </span>
                            <span class="title">Voice</span>
                            <div className='ms-TooltipHost root-451'>
                                <button className='ms-Button ms-Button--icon icon-button root-309'>
                                    <span class='ms-Button-flexContainer flexContainer-189'>
                                        <img src={ImportantIcon}></img>
                                        <img style={{marginLeft:"130px"}} src ={Eraser}
                                        onClick={()=>{
                                            SetVoiceSelected('Jenny');
                                             props.setVoice('Jenny');
                                             props.setVoiceSelected(false);
                                             setIsVoice(false);
                                             props.setIsVoiceChanged(true)
                                        }}></img>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {(voiceSliderClick) ? <>
                    <div className='body1'>
                        <div className='flex-row'>
                            <div className="children flex-column">
                                <div className='qs-choose-language'>
                                    <div className='ms-ComboBox-container'
                                        style={{ textAlign: 'left' }}>
                                        <label>Language</label>
                                        <div>
                                            <Box sx={{ width:"250px" }}>
                                                <FormControl fullWidth>
                                                    <Select
                                                        labelId="select-box-label"
                                                        id="select-box-container"
                                                        value={langSelected}
                                                        label="Select Voice"
                                                        onChange={handleChange}
                                                        sx={{ fontSize: "14px",fontWeight: "400",
                                                            lineHeight:"100%",
                                                            color:"rgb(50, 49, 48)" }}
                                                    >
                                                        <MenuItem sx={{ fontSize: "14px",fontWeight: "400",lineHeight:"100%",color:"rgb(50, 49, 48)" }} value={'English (United States)'}>English (United States)</MenuItem>
                                                        <MenuItem sx={{ fontSize: "14px",fontWeight: "400",lineHeight:'100%',color:"rgb(50, 49, 48)" }} value={'English (Philippines)'}>English (Philippines)</MenuItem>
                                                        <MenuItem sx={{ fontSize: "14px",fontWeight: "400" ,lineHeight:"100%",color:"rgb(50, 49, 48)"}} value={'Zulu (South Africa)'}>Zulu (South Africa)</MenuItem>
                                                    </Select>
                                                </FormControl>
                                            </Box>
                                        </div>
                                        <Box >
                                             {displayOptions(langSelected)}
                                            </Box>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </> : <></>}
            </div>
            <div className='panel-frame-jn5c3tzmdb acc-panel-frame'>
                <div className='panel-body'>
                    <div className='tuning-control-wrapper-rq1cjbhtkv '>
                        <div className='header1'>
                            <span className="collapse-btn-wrapper1">
                                <button className="ms-Button ms-Button--icon root-309"
                                onClick={()=>setProSliderClick(!proSliderClick)}>
                                    <span className='ms-Button-flexContainer flexContainer-189'>
                                        {(!proSliderClick) ? <img src={ArrowRight} /> : <img src = {ArrowRightReverse}/>}
                                    </span></button>
                            </span>
                            <span class="title">Pronunciation</span>
                            <div className='ms-TooltipHost root-451'>
                                <button className='ms-Button ms-Button--icon icon-button root-309'>
                                    <span class='ms-Button-flexContainer flexContainer-189'>
                                        <img src={ImportantIcon}></img>
                                        <img style={{marginLeft:"100px"}} src ={Eraser}
                                        onClick={()=>{
                                            if(alias){
                                                setAliasValue("");
                                                props.setAliasValue("");
                                                //props.setIsVoice(true);
                                                props.setIsAlias(false);
                                                props.setIsVoiceChanged(true)
                                            
                                            }
                                        }}></img>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {(proSliderClick) ? <><div className='body1'>
                        <div className='flex-row'>
                            <div className="children flex-column">
                                <div className='qs-choose-language'>
                                    <div className='ms-ComboBox-container'
                                        style={{ textAlign: 'left',
                                            display: "flex",
                                            flexDirection: "column"
                                        }}>
                                        <div>
                                        <label className={(alias) ? "label1 underline" :"label1"} style={{ marginRight:"15px"}}
                                        onClick={()=>{
                                            setAlias(true)
                                            setPhoneme(false)}}>Alias</label>
                                        <label className={(phoneme)?"label1 underline":"labe1"}
                                        style={{marginLeft:"15px"}}
                                        onClick={()=>{
                                            setPhoneme(true)
                                            setAlias(false)}}>Phoneme</label>
                                            </div>
                                            {(!alias) && <div className='alias-Container'>
                                               <button className='ms-Button ms-Button--icon icon-button root-309'
                                               style={{marginLeft:"1px",
                                                marginTop:"10px" }}
                                                onClick={()=>setModal(true)}><span className='root-507'>Click to edit<img src={EditorPen}/>phoneme</span></button> 
                                            </div>}
                                            {(!phoneme) && <div className='phoneme-Range-Container'>
                                                <div style = {{marginLeft:"1px",
                                                marginTop:"10px"}}><input type = "text" placeholder='Input word to repalce the selected text'
                                                className='input-alias'
                                                value={aliasValue}
                                                onChange = {(e)=>handleAddAlias(e)}/>
                                                <button className={(isAlias) ? "buttonTick-active" : "buttonTick"}
                                                onClick={()=>handleAliasSubmit()}><img src = {Tick} onClick={()=>setSuccess(true)}/></button></div>
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>:<></>}
            </div>
            <div className='panel-frame-jn5c3tzmdb acc-panel-frame'>
                <div className='panel-body'>
                    <div className='tuning-control-wrapper-rq1cjbhtkv '>
                        <div className='header1'>
                            <span className="collapse-btn-wrapper1">
                                <button className="ms-Button ms-Button--icon root-309"
                                onClick={()=>setLexiconSliderClicl(!lexiconSliderClick)}>
                                    <span className='ms-Button-flexContainer flexContainer-189'>
                                        {(!lexiconSliderClick) ? <img src={ArrowRight} /> :<img src ={ArrowRightReverse}/>}
                                    </span></button>
                            </span>
                            <span class="title">Lexicon</span>
                            <div className='ms-TooltipHost root-451'>
                                <button className='ms-Button ms-Button--icon icon-button root-309'>
                                    <span class='ms-Button-flexContainer flexContainer-189'>
                                        <img src={ImportantIcon}></img>
                                        <img style={{marginLeft:"120px"}} src={Eraser}
                                        onClick={()=>{
                                            props.setIsLexicon(false);
                                            props.setLexiconID();
                                            setSelectedLexi();
                                            setSelectedLexiName('');
                                        }}></img>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {(lexiconSliderClick)? <>
                    <Box sx={{ width:"250px",marginLeft:'20px' }}>
                                                <FormControl fullWidth>
                                                <InputLabel id="demo-simple-select-label"
                                                >Select Lexicon File</InputLabel>
                                                    <Select
                                                    labelId="demo-simple-select-label"
                                                    id="demo-simple-select"
                                                        value={selectedLexiconName}
                                                        label="Select lexicon"
                                                        onChange={handleChangeLexicon}
                                                        sx={{ fontSize: "14px",fontWeight: "400",
                                                            lineHeight:"100%",
                                                            color:"rgb(50, 49, 48)" }}
                                                    >
                                                        {dispalyLexiconData()}
                                                    </Select>
                                                </FormControl>
                                            </Box></>
                :<></>}
            </div>
            <div className='panel-frame-jn5c3tzmdb acc-panel-frame'>
                <div className='panel-body'>
                    <div className='tuning-control-wrapper-rq1cjbhtkv '>
                        <div className='header1'>
                            <span className="collapse-btn-wrapper1">
                                <button className="ms-Button ms-Button--icon root-309"
                                onClick={()=>setRateSliderClick(!rateSliderClick)}>
                                    <span className='ms-Button-flexContainer flexContainer-189'>
                                        {(!rateSliderClick)?<img src={ArrowRight}/>:<img src ={ArrowRightReverse}/>}
                                    </span></button>
                            </span>
                            <span class="title">Rate</span>
                            <div className='ms-TooltipHost root-451'>
                                <button className='ms-Button ms-Button--icon icon-button root-309'>
                                    <span class='ms-Button-flexContainer flexContainer-189'>
                                        <img src={ImportantIcon}></img>
                                        <img style={{marginLeft:"120px"}} src={Eraser}
                                        onClick={()=>{
                                            props.setIsRateSelected(false);
                                            setRateRelative(100);
                                        }}/>
                                    </span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {(rateSliderClick) && <div className='body1'>
                        <div className='flex-row'>
                            <div className="children flex-column">
                                <div className='qs-choose-language'>
                                    <div className='ms-ComboBox-container'
                                        style={{ textAlign: 'left',
                                            display: "flex",
                                            flexDirection: "column"
                                        }}>
                                        <div>
                                        <label className={(relative) ? "label1 underline" :"label1"} style={{ marginRight:"15px"}}
                                        onClick={()=>{
                                            setRelative(true)
                                            setConstant(false)}}>Relative</label>
                                        <label className={(constant)?"label1 underline":"labe1"}
                                        style={{marginLeft:"15px"}}
                                        onClick={()=>{
                                            setConstant(true)
                                            setRelative(false)}}>Constant</label>
                                            </div>
                                            {(relative) && <div className='Relative-Range-Container'>
                                                <div className='rangeVal'>
                                                <span>{"0.1"}</span>
                                            <input type='range' 
                                            style={{marginLeft:"5px",
                                                marginRight:"5px"
                                            }}
                                            className='input'
                                             min='10' 
                                             max="300"
                                             value={rateRelative}
                                            onChange={(e)=>{
                                                setRateRelative((parseInt(e.target.value)))}}/>
                                                <span>3</span>
                                                </div>
                                        
                                            <Box sx={{border:"0.5px solid black",
                                                display:"flex",
                                                flexDirection:"row",
                                                justifyContent:"space-between"
                                            }}>
                                                <input type = "number" 
                                                placeholder='Enter a rate'
                                                //min = {"0"}
                                                //max = {"3"}
                                                //value = {(inputValue <= 3)? inputValue : prevValue}
                                                style={{maxWidth:"135px"}}
                                                value={inputValue}
                                                onChange={(e) =>{
                                                    
                                                        setInputValue(e.target.value);
                                                    }}/>
                                                <button style={{height:"35px"}} className={"buttonTick-active"}
                                                onClick={()=>InputRateSubmit()}><img src = {Tick}/></button>
                                                <span style={{margin:"8px"}}>X{(rateRelative/100)}</span>
                                                <div style={{margin:"8px"}}>
                                                <img src={ActionsArrowIcon} onClick={()=>increseRate()}/>
                                                <img src ={actionDropDownReverse} onClick={()=>decreaseRate()}/>
                                                </div>
                                            </Box>
                                            {<div style={{color : "rgb(0, 120, 212)",
                                                marginTop:"4px"
                                            }}>Please select a rate in range of 0.1 to 3</div>}
                                            </div>}
                                            {(constant) && <div className='Constant-Range-Container'>
                                                <Box sx = {{width : "240px"}}>
                                                    <FormControl fullWidth>
                                                    <Select
                                                        labelId="select-box-label"
                                                        id="select-box-container"
                                                        value={rateConstant}
                                                        label="Select Rate"
                                                        onChange={handleChangeRate}
                                                        sx={{ fontSize: "14px",fontWeight: "400",
                                                            lineHeight:"100%",
                                                            color:"rgb(50, 49, 48)" }}
                                                    >
                                                        <MenuItem sx={{ fontSize: "14px",fontWeight: "400",lineHeight:"100%",color:"rgb(50, 49, 48)" }} value={'Default'}>Default</MenuItem>
                                                        <MenuItem sx={{ fontSize: "14px",fontWeight: "400",lineHeight:"100%",color:"rgb(50, 49, 48)" }} value={'x-slow'}>X-Slow</MenuItem>
                                                        <MenuItem sx={{ fontSize: "14px",fontWeight: "400",lineHeight:'100%',color:"rgb(50, 49, 48)" }} value={'slow'}>Slow</MenuItem>
                                                        <MenuItem sx={{ fontSize: "14px",fontWeight: "400" ,lineHeight:"100%",color:"rgb(50, 49, 48)"}} value={'medium'}>Medium</MenuItem>
                                                        <MenuItem sx={{ fontSize: "14px",fontWeight: "400" ,lineHeight:"100%",color:"rgb(50, 49, 48)"}} value={'fast'}>Fast</MenuItem>
                                                        <MenuItem sx={{ fontSize: "14px",fontWeight: "400" ,lineHeight:"100%",color:"rgb(50, 49, 48)"}} value={'x-fast'}>X-Fast</MenuItem>
                                                    </Select>
                                                    </FormControl>
                                                    </Box> 
                                            </div>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>}
            </div></>}
            {(modal) && 
            <AliasModal
            textselected = {textselected}
            handleClose = {()=>setModal(false)}
            fromLexicon = {false}
            setAddLexiModal = {setAddLexiModal}
            setPhonemeValue = {setPhonemeValue}
            setIsPhoneme = {setIsPhoneme}
            />}
            {(addLexiModal) &&
            <AddLexiModal
            modalOpen={addLexiModal}
            phoneme = {phonemeValue}
            grapheme = {textselected}
            handleClose={()=>{setAddLexiModal(false)}}/>
            }
            {(inputError) && <AlertMessagePopUpModal 
            color={"#0078d4"} 
            content={"Selected Rate is out of Range"} 
            showModal={inputError}
             handleClose={() => setInputError(false)} />}
             {(success) && <SuccesModal
              handleClose = {()=>setSuccess(false)}
              content = "Rate Added SuccessFully"/>}
        </>
    )
}
export default RightPanel;