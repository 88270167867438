import { useState, useEffect } from "react";
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import '../../../node_modules/react-bootstrap-table2-filter/dist/react-bootstrap-table2-filter.min.css';
import '../../../node_modules/react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import '../../../node_modules/react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit.min.css';
import { Type } from 'react-bootstrap-table2-editor';
import cellEditFactory from 'react-bootstrap-table2-editor';
import { arrowIconBlue, documentIcon, folderIcon, imsLoader } from "../../assets";
import "./TSS.css";
import AudioContent from "./AudioContent";
import ApiServices from "../../utils/apiServices";
import { useHistory } from "react-router-dom";
import CellEdit from "./CellEdit";
import Services from "../../utils/services";



const TSSMyFile = (props) => {
  const [selectedResult, setSelectedResult] = useState([]);
  const [legacyFolderData,setLegacyFolderData] = useState([]);
  const [legacyDocumentData,setLegacyDocumentData] = useState([])
  const [isLoaded, setIsLoaded] = useState(false);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [seletedFolderName,setFolderName]=useState(['My Files']);
  const [checkedSelect, setCheckedSelect] = useState([]);
  const [row, setRowSelect] = useState({})
  const [checked, setCheck] = useState(false);
  const [loader,setLoader] = useState(false);

  let history = useHistory();
  //const [clickCheckBox , setClickCheckBox] = useState(false);
  useEffect(()=>{
  setSelectedDocument(null);
  setSelectedFolder(null);
 },[(props.manageFileProp)]);

  useEffect(()=>{
    if(selectedFolder !== null){
    let filterDocument = legacyDocumentData.filter((value)=>{
     return selectedFolder?.folderid === value?.folderid
    });
    let filterFolder = legacyFolderData.filter((value)=>{
     return selectedFolder?.folderid === value?.parentfolderid && value?.folderid !== value?.parentfolderid;
    });
    setSelectedResult(filterFolder.concat(filterDocument));}
   },[selectedFolder?.folderid, !props.refresh]);
   
   useEffect(()=>{
     setLoader(true)
      resRefresh();
      setCheckedSelect([])
      props.setSelected([]);
     
   },[props.refresh])

   useEffect(()=>{
    api()
   },[(props.resFiles) , (props.resFolder)])
   
   useEffect(()=>{
    props.setSelected(checkedSelect);
   },[checkedSelect])

   useEffect(()=>{
    handleSelectRow ( row , checked );
   },[row,checked])

   async function resRefresh(){
    let service = new ApiServices();
    let res1 =await service.getAllDocDetails();
    let res2 = await service.getAllFolderDetails();
    if(res1){
      setLegacyDocumentData(res1)
    }
    if(res2){
      setLegacyFolderData(res2)
    }
    setLoader(false)
   props.setRefresh(false)
   if(seletedFolderName.length === 1){
    api()
   }
  }

  async function api(){
    let service = new ApiServices();
    let myapiResponseDocument2 =await service.getAllDocDetails();
    let myapiResponseFolder2 = await service.getAllFolderDetails();
    //let myapiResponseDocument2 = props.resFiles;
    //let myapiResponseFolder2 = props.resFolder;
   if (myapiResponseDocument2.length > 0 && myapiResponseFolder2.length > 0) {
    let filterDocument = myapiResponseDocument2.filter((value)=>{
      return value?.folderid === 0;
     })
     let filterFolder = myapiResponseFolder2.filter((value)=>{
       return value?.folderid === value?.parentfolderid;
     })
     
     setSelectedResult(filterFolder.concat(filterDocument));
     setSelectedFolder(null)
    setFolderName(["My Files"])
    setIsLoaded(true);

    /*if(myapiResponseDocument2){
      setLegacyDocumentData(myapiResponseDocument2)
    }
    if(myapiResponseFolder2){
      setLegacyFolderData(myapiResponseFolder2)
    }*/
  }
    return myapiResponseDocument2
  }

  let columns = [
    {
      headerStyle: {
        paddingTop: '5px',
        width: "5%",
      },
      style: {
        padding:"1px"
      },
      hidden: false,
      dataField:"folderid",
      formatter: (cell,row)=>{
        return <div style={{marginTop: "10px"}}><input type="checkbox" id={row} name="row" value={row} onChange={(e)=>{
          setRowSelect(row);
          setCheck(e.target.checked);
          //props.setSelected(row);
        }}/></div>
      },
    },
    {
      dataField: "doctitle" ,
      text: 'Name',
      editable: false,
      headerStyle: {
        textAlign:"left",
        paddingLeft:"10px",
        width:"30%"
      },
      style: {
         padding:"1px"
      },
      formatter: (cell, row) => {
        return <div style={{padding: "5px",textAlign: "left"}} className="TSS-file-or-folder-name-cloumn">
          {(row.foldername) ? <>
          <span style={{cursor:"pointer"}} onClick={(e)=>{setSelectedFolder(row);
          props.selectedFolder(row);
          history.push(`/TTSHome/${row.folderid}`)
            setFolderName([...seletedFolderName,row.foldername])}}><img src={folderIcon} style={{height:"25px"}}/>{row.foldername}</span>
          </> : 
          <><span style={{cursor:"pointer"}} onClick = {(e)=>{setSelectedDocument(row);
            props.setRightPannel();
            history.push(`/TTSHome/${row.folderid}`)
            setFolderName([...seletedFolderName,row.doctitle])}}><img src={documentIcon} style={{height:"25px"}}/>{row.doctitle}</span>
          </>}</div>;
      }
      
    },
    {
      dataField: 'filetype',
      text: 'File Type',
      editable: false,
      headerStyle: {
        width: '10%',
        height: "1%"
      },
      style: {
         padding:"1px"
      },
      formatter: (cell, row) => {
        return <div style={{padding: "5px",textAlign: "center"}}>
          {(row.foldername) ? <span>Folder
          </span> : 
          <span> Text File
          </span>}</div>;
      }
    },
    {
      dataField: 'tag',
      text: 'Tags',
      editable: true,
      headerStyle: {
        width: "20%"
      },
      style: {
         padding:"1px"
      },
      editorRenderer: (value,rowValue,row) => {
        return (
          <CellEdit
          handleSaveEdit = {handleSaveEdit}
          prevValue = {rowValue}
          row = {row}
          handleCancelEdit = {()=>{setLoader(true);
            resRefresh()
          }}
          />
        )
    }
    },
    {
      dataField: 'datemodified',
      text: 'Date Modified',
      editable: false,
      headerStyle: {
      },
      style: {
         padding:"1px"
      }
    }
  ]
  const handleSaveEdit = async (value,prevValue,isFolder,id) => {
   let service = new ApiServices();
   let res;
   if( prevValue !== value ){
   if(isFolder){
    res = service.updateFolderTag(value,id);
   }
   else{
   res = service.updateDocumentTag(value,id);
  }
  }
  if(res){
    setLoader(true)
    resRefresh()
    //window.location.reload();
}
}

  const handlePrevButton = (e) =>{
  let PrevTitle = ((e.target.outerText).split(" "))[0];
  if(PrevTitle !== "My"){
    
  let filterItemsDoc = legacyDocumentData.filter((value)=>{
    return value.doctitle === PrevTitle;
  })
  let filterItemFolder = legacyFolderData.filter((value)=>{
    return value.foldername === PrevTitle ;
  });
  setSelectedFolder(filterItemFolder[0])
  let index = seletedFolderName.indexOf(PrevTitle)
  setFolderName(seletedFolderName.slice(0,index + 1))
}
  
  else{
    let filterItemsDoc=legacyDocumentData.filter((value)=>{return value?.folderid === 0});
    let filterItemFolder = legacyFolderData.filter((value)=>{return  value?.folderid === value?.parentfolderid})
    setSelectedResult(filterItemFolder.concat(filterItemsDoc));
    setSelectedFolder(null)
    setFolderName(["My Files"]);
  }
   //setSelectedDocument(filterItemsDoc)
  }

 const handleSelectRow = (row, isSelect) => {
    if (isSelect) {
        if(row.docid) {
          setCheckedSelect ([...checkedSelect, row]);
        }
        else {  
          setCheckedSelect ([...checkedSelect, row])}
        }
   else {
    if(row.docid) {setCheckedSelect (checkedSelect.filter(x => x !== row))}
    else{setCheckedSelect (checkedSelect.filter(x => x !== row))}
        }
      }     

     const handleSelectAllRow = (isSelect, rows) => {
       // const rows = rows.map(r => r);
        if (isSelect) {
            setCheckedSelect(rows)
        } else {
            
                setCheckedSelect(checkedSelect.filter((el) => !rows.includes(el)))//[],
        }
    }

    /*const selectRow = {
        mode: 'checkbox',
        clickToSelect: true,
        onSelect: handleSelectRow,
        onSelectAll: handleSelectAllRow,
        selected: checkedSelect
    };*/
  return (
    (loader)?
      <div style={{ textAlign: "center", marginBottom: "10px" }}>
          <img src={imsLoader} alt="Loading Filters" style={{ width: "10%" }} />
      </div> : (selectedDocument) ? <>
      {history.push(`/TTSAudioContentPage/${selectedDocument.docid}`)}</> : <>
   <div className="Folder-Name-Container"> {seletedFolderName.map((value)=>{return<span style={{cursor:"pointer"}}
   onClick={(e)=>handlePrevButton(e)}
   >{`${value} >> `}</span>})}</div>
   <div className="Table-CXonatainer"
  style={{
    width:"100%",
    height:"60%",
    marginTop: "5px",
    /*marginBottom: "20px",
    padding:"8px"*/
  }}>
    <BootstrapTable
    //ref={x => this.table = x}
      keyField={'docid'}
      data={selectedResult}
      columns={columns}
      loading={true}  //only loading is true, react-bootstrap-table will render over
      cellEdit={cellEditFactory(
        {
            mode: 'dbclick',
            // blurToSave: true,
            /*afterSaveCell: async (oldValue, newValue, row, column) => {
                if (oldValue !== newValue) {
                    //await this.updateNotebookNotes(row.id, newValue)
                }
            },*/
            
        }
    )}
    />
    </div>
    </>
)
}
export default TSSMyFile;